import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import moment from 'moment-timezone';
import Alerts from '../../components/Alerts';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { Trash } from 'lucide-react';

const GET_SHEET_TRIGGER = gql`
  query SheetsByUserId($uid: String!) {
    sheetsByUserId(uid: $uid) {
      id
      uid
      sheetId
      deviceId
      deviceName
      message
      event
      phone
      createdAt
    }
  }
`;

function SheetRecords({ setMessage, setIsClicked, setError, deleteTrigger }) {
    const [records, setRecords] = useState(null);
    const [sheetError, setSheetError] = useState('');
    const userInfo = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : { uid: '' };
    const uid = userInfo.uid;

    const { data, error } = useQuery(GET_SHEET_TRIGGER, {
        variables: { uid },
    });

    const columns = [
        { field: 'createdAt', headerName: 'Date Time', minWidth: 250, align: 'left' },
        { field: 'sheetId', headerName: 'Sheet ID', minWidth: 300, align: 'left' },
        { field: 'deviceId', headerName: 'Device ID', minWidth: 300, align: 'left' },
        { field: 'deviceName', headerName: 'Device Name', minWidth: 300, align: 'left' },
        { field: 'message', headerName: 'Message', minWidth: 300, align: 'left' },
        { field: 'phone', headerName: 'Phone', minWidth: 300, align: 'left' },
        { field: 'event', headerName: 'Trigger Key', minWidth: 300, align: 'left' },
        {
            field: 'actions',
            type: 'actions',
            width: 200,
            headerName: 'Actions',
            align: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Trash />}
                    label="Delete"
                    onClick={() => deleteTrigger(params.row)}
                />,
            ],
        },
    ];

    useEffect(() => {
        if (data && data.sheetsByUserId && data.sheetsByUserId.length > 0) {

            console.log("data", data)

            // const sheetRecords = data.sheetsByUserId
            //     .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            //     .map((user) => ({
            //         id: moment(new Date(Number(user.createdAt)))
            //             .tz('Asia/Kolkata')
            //             .format('DD-MM-YYYY h:mm:ss.SSS A'),
            //         ...user,
            //         createdAt: moment(new Date(Number(user.createdAt)))
            //             .tz('Asia/Kolkata')
            //             .format('DD-MM-YYYY h:mm:ss.SSS A'),
            //     }));

            const sheetRecords = [...data.sheetsByUserId] // Create a shallow copy of the array
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((user) => ({
                    id: moment(new Date(Number(user.createdAt)))
                        .tz('Asia/Kolkata')
                        .format('DD-MM-YYYY h:mm:ss.SSS A'),
                    ...user,
                    createdAt: moment(new Date(Number(user.createdAt)))
                        .tz('Asia/Kolkata')
                        .format('DD-MM-YYYY h:mm:ss.SSS A'),
                }));


            setRecords(sheetRecords);
        } else {
            setRecords([]);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setSheetError(error.message);
            setTimeout(() => {
                setSheetError('');
            }, 5000);
        }
    }, [error]);

    return (
        <>
            {sheetError && <Alerts alertType="Error">{sheetError}</Alerts>}
            {records && records.length >= 0 ? (
                <DataTable columns={columns} data={records} />
            ) : (
                <p className="text-xl font-semibold text-red-500">No records found</p>
            )}
        </>
    );
}

export default SheetRecords;
