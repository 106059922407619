import axios from "axios";
import readXlsxFile from "read-excel-file";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Emojis from "../../util/Emojis";
import Container from "../../components/Container";
import { DownloadCloud } from "lucide-react";
import CronStringGenerator from "../../util/CronStringGenerator";
import { gql, useQuery } from "@apollo/client";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Alerts from "../../components/Alerts";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const GET_TEMPLATES = gql`
    query($uid: String!){
        templateByUID(uid:$uid) {
            id,
            templateName,
            body
        }
    }
`
const MultiMessage = () => {
    const { refetch, data, loading } = useQuery(DEVICE_GET);

    const [devices, setDevices] = useState([]);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [selectedFile, setSelectedFile] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFileImportSelected, setIsFileImportSelected] = useState(false);
    const [isScheduleProcess, setIsScheduleProcess] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
    const [templates, setTemplates] = useState(null);
    const [sentMessage, setSentMessage] = useState(0);
    const [totalMessage, setTotalMessage] = useState(1);
    const [isClicked, setIsClicked] = useState(false);
    const [templateSelected, setTemplateSelected] = useState(false);

    const fileImportData = useRef([]);
    const cursorRef = useRef(0);
    const dateTimeRef = useRef({});

    const phoneRef = useRef([]);
    const fileRef = useRef();
    const phonesFile = useRef();
    const quillRef = useRef(null);
    const selectedDeviceRef = useRef([]);

    const [fileName, setFileName] = useState('Choose Excel File');

    const userInfo = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
        variables: {
            uid: userInfo.uid
        }
    });

    let i = 0;
    let phones = [];
    let apiURL = "";

    function formatWhatsAppMessage(message) {
        // Remove all HTML entities (e.g., &nbsp;, &amp;, etc.)
        message = message.replace(/&[a-zA-Z0-9#]+;/g, " ");

        // Remove all placeholder words from the string
        message = message.replace(/\{\{[^}]+\}\}/g, "").replace(/\s{2,}/g, " ").trim();

        // Replace <em> tags with WhatsApp italic formatting
        message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

        // Replace <strong> tags with WhatsApp bold formatting, allowing optional spaces around the text
        message = message.replace(/<strong>\s*(.*?)\s*<\/strong>/g, '*$1*');

        // Replace <u> tags (underline) with emphasis (using _ for emphasis since underline isn't supported)
        message = message.replace(/<u>(.*?)<\/u>/g, '_$1_');

        // Ensure <p> tags add new lines
        message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
        message = message.replace(/<p>/g, ''); // Remove opening <p> tags

        // Convert ordered list <ol> into plain numbered list
        message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            let counter = 1;
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `${counter}. ${listItem}\n`; // Add numbering
                counter++;
            });
            return formattedList; // Return formatted list
        });

        // Convert unordered list <ul> into plain bullet list
        message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
            let formattedList = '\n'; // Newline before the list
            // Process each <li> item
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `• ${listItem}\n`; // Add bullet points
            });
            return formattedList; // Return formatted list
        });

        // Remove any other remaining HTML tags
        message = message.replace(/<[^>]*>/g, '');

        // Handle adjacent formatting symbols and ensure no spaces are lost
        message = message.replace(/_([\s\S]+?)_|\*([\s\S]+?)\*/g, function (match, italic, bold) {
            if (italic) return `_${italic}_`;
            if (bold) return `*${bold}*`;
            return match;
        });

        return message.trim(); // Trim any trailing spaces or newlines
    }

    const replacePlaceholders = (messageTemplate, dataObject) => {
        // Use a regex to match placeholders like {{key}} in the message template
        let formatedMsg = messageTemplate.replace(/\{\{(\w+)\}\}/g, (_, placeholder) => {
            // Match placeholder with a lowercase key in dataObject
            const value = dataObject[placeholder.toLowerCase()];
            return value !== undefined ? value : `{{${placeholder}}}`; // Retain the placeholder if not found
        });

        return formatWhatsAppMessage(formatedMsg)
    };

    const sendFormatedScheduleMessage = (phone, fmsg, jsonObj) => {

        let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

        if (phone && phone !== "" && phone.toString().length === 12) {

            var data = JSON.stringify({
                id: userInfo.uid,
                phones: phone.toString().replace(/[^0-9]|\s+/g, "").trim(),
                message: replacePlaceholders(fmsg, jsonObj),
                dateTime: dateTimeRef.current.value,
                cronString: CronStringGenerator(dateTimeRef.current.value),
                uid: userInfo.uid,
                deviceName: selectedDeviceRef.current[0],
                file: ""
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {

                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {

                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {

                            if (message.messageType === "defaultMessage") {

                                sendFormatedScheduleMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendFormatedScheduleMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }

                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setStatus(msgResp);
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFormatedScheduleFileMessage = (phone, fmsg, jsonObj) => {

        let apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;
        phones = phoneRef.current.value.split(",");
        setTotalMessage(Number(phones.length));

        if (
            phone &&
            phone !== "" &&
            phone.toString().length === 12
        ) {

            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("uid", userInfo.uid);
            formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
            formData.append("deviceName", selectedDeviceRef.current[0]);
            formData.append("file", selectedFile);
            formData.append("message", replacePlaceholders(fmsg, jsonObj));
            formData.append("dateTime", dateTimeRef.current.value);
            formData.append("phones", phone.toString().replace(/[^0-9]|\s+/g, "").trim());

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {

                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            if (message.messageType === "defaultMessage") {

                                sendFormatedScheduleFileMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendFormatedScheduleFileMessage(fileImportData.current[i].phone, fileImportData.current[i].phone.message, fileImportData.current[i]);

                            }

                        } else {

                            i = 0;
                            phones = [];
                            apiURL = "";
                            setStatus(msgResp);
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }

    };

    const sendScheduleFileMessageMulti = (phone, fmsg, jsonObj) => {

        let apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;

        if (phone && phone !== "" && phone.toString().length === 12) {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("uid", userInfo.uid);
            formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
            formData.append("deviceName", selectedDeviceRef.current[0]);
            formData.append("file", selectedFile);
            formData.append("message", replacePlaceholders(fmsg, jsonObj));
            formData.append("dateTime", dateTimeRef.current.value);
            formData.append("phones", phone.replace(/[^0-9]|\s+/g, "").trim());

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {

                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {

                        i++;
                        //Set Msg sent count
                        setSentMessage(i);
                        if (i < phones.length) {
                            if (message.messageType === "defaultMessage") {

                                sendScheduleFileMessageMulti(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendScheduleFileMessageMulti(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setStatus(msgResp);
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }

    };

    const sendScheduleFileMessage = (phone, fmsg, jsonObj) => {

        const apiURL = `https://messagesapi.co.in/schedule/addFileMessage`;
        phones = phoneRef.current.value.split(",");
        setTotalMessage(Number(phones.length));

        if (phones.length > 1) {
            sendScheduleFileMessageMulti(phones[i]);
        }
        else {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("uid", userInfo.uid);
            formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
            formData.append("deviceName", selectedDeviceRef.current[0]);
            formData.append("file", selectedFile);
            formData.append("message", replacePlaceholders(fmsg, jsonObj),);
            formData.append("dateTime", dateTimeRef.current.value);
            formData.append("phones", phone.replace(/[^0-9]|\s+/g, "").trim());

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message);
                });
        }
    };

    const sendScheduleMessageMulti = (phone, fmsg, jsonObj) => {

        let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

        if (phone && phone !== "" && phone.toString().length === 12) {

            var data = JSON.stringify({
                id: userInfo.uid,
                phones: phone.replace(/[^0-9]|\s+/g, "").trim(),
                message: replacePlaceholders(fmsg, jsonObj),
                dateTime: dateTimeRef.current.value,
                cronString: CronStringGenerator(dateTimeRef.current.value),
                uid: userInfo.uid,
                deviceName: selectedDeviceRef.current[0],
                file: ""
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {

                        i++;
                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {

                            if (message.messageType === "defaultMessage") {

                                sendScheduleMessageMulti(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendScheduleMessageMulti(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setStatus(msgResp);
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendScheduleMessage = () => {

        setIsScheduleProcess(true);

        phones = phoneRef.current.value.split(",");

        setTotalMessage(Number(phones.length));

        if (selectedDeviceRef.current.length === 0) {
            setError("Please select atleast one device");
            return
        }

        let invalidNumbers = checkInvalidNumbers(phones);

        if (invalidNumbers.length === 0) {

            if (phoneRef.current.value) {

                if (phoneRef.current.value.length !== 0) {

                    if (isSelected) {

                        if (fileImportData.current.length > 0 && isFileImportSelected) {

                            if (message.messageType === "defaultMessage") {

                                sendFormatedScheduleFileMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {

                                sendFormatedScheduleFileMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);
                            }


                        } else {

                            sendScheduleFileMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                        }
                    } else {

                        if (fileImportData.current.length > 0 && isFileImportSelected) {

                            if (message.messageType === "defaultMessage") {

                                sendFormatedScheduleMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {

                                sendFormatedScheduleMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);
                            }


                        } else {

                            if (phones.length > 1) {

                                sendScheduleMessageMulti(phones[i]);
                            }
                            else {

                                let apiURL = `https://messagesapi.co.in/schedule/addMessage`;

                                let phone = phoneRef.current.value.replace(/[^0-9]|\s+/g, "").trim();

                                if (phone && phone !== "" && phone.toString().length === 12) {

                                    var data = JSON.stringify({
                                        id: userInfo.uid,
                                        phones: phone,
                                        message: replacePlaceholders(message.message, fileImportData.current[i]),
                                        dateTime: dateTimeRef.current.value,
                                        cronString: CronStringGenerator(dateTimeRef.current.value),
                                        uid: userInfo.uid,
                                        deviceName: selectedDeviceRef.current[0],
                                        file: ""
                                    });

                                    var config = {
                                        method: "POST",
                                        url: apiURL,
                                        headers: {
                                            "Content-type": "application/json",
                                        },
                                        data: data,
                                    };

                                    axios(config)
                                        .then(function (response) {
                                            let msgResp = response.data.message;
                                            let msgStatus = response.data.status;

                                            i++;

                                            setSentMessage(i);

                                            if (msgStatus !== "error") {

                                                setStatus(msgResp);

                                            } else {
                                                setError(msgResp);
                                            }
                                        })
                                        .catch(function (error) {
                                            setError(error.response.data.message);
                                        });
                                } else {
                                    setError(`Invalid mobile number ${phone}`);
                                }

                            }

                        }

                    }
                } else {

                    setError("Phone number is missing");
                }
            } else {
                setError("Phone is not valid");
            }
        } else {
            setError(`Check this number ${invalidNumbers.join(",")}`);
            invalidNumbers = [];
        }
    }

    const sendFormatedMessage = (phone, fmsg, jsonObj) => {
        if (phone && phone !== "" && phone.toString().length === 12) {
            var data = JSON.stringify({
                id: userInfo.uid,
                phone: phone.toString().trim(),
                message: replacePlaceholders(fmsg, jsonObj),
                name: selectedDeviceRef.current[0],
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;


                    if (msgStatus !== "error") {
                        setStatus(msgResp);

                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {

                            if (message.messageType === "defaultMessage") {

                                sendFormatedMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendFormatedMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }

                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setSuccess("Message sent successfully!");
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFormatedFileMessage = (phone, fmsg, jsonObj) => {

        if (
            phone &&
            phone !== "" &&
            phone.toString().length === 12
        ) {

            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("file", selectedFile);
            formData.append("message", replacePlaceholders(fmsg, jsonObj));
            formData.append("phone", phone);
            formData.append("name", selectedDeviceRef.current[0]);

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {

                        setStatus(msgResp)

                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {

                            if (message.messageType === "defaultMessage") {

                                sendFormatedFileMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendFormatedFileMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);
                            }


                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setSuccess("Message sent successfully!");
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }

    };

    const sendFileMessageMulti = (phone, fmsg, jsonObj) => {

        if (phone && phone !== "" && phone.toString().length === 12) {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("file", selectedFile);
            formData.append("message", replacePlaceholders(fmsg, jsonObj));
            formData.append("deviceName", selectedDeviceRef.current[0]);
            formData.append("phone", phone);

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp)
                        i++;
                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            if (message.messageType === "defaultMessage") {
                                sendFileMessageMulti(fileImportData.current[i].phone, fileImportData.current[i], fileImportData.current[i]);
                            } else {
                                sendFileMessageMulti(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setSuccess("Message sent successfully!");
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }

    };

    const sendFileMessage = (deviceName) => {

        apiURL = `https://messagesapi.co.in/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;

        phones = phoneRef.current.value.split(",");
        setTotalMessage(Number(phones.length));

        if (phones.length > 0) {
            sendFileMessageMulti(phones[i]);
        }
    };

    const sendMessageMulti = (phone, fmsg, jsonObj) => {

        if (phone && phone !== "" && phone.toString().length === 12) {
            var data = JSON.stringify({
                id: userInfo.uid,
                phone: phone.replace(/[^0-9]|\s+/g, "").trim(),
                message: replacePlaceholders(fmsg, jsonObj),
                name: selectedDeviceRef.current[0],
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;
                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            if (message.messageType === "defaultMessage") {

                                sendMessageMulti(fileImportData.current[i].phone, message.message, fileImportData.current[i]);
                            } else {
                                sendMessageMulti(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                            setSuccess("Message sent successfully!");
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.message);
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendMessage = () => {

        setIsClicked(true);

        apiURL = `https://messagesapi.co.in/chat/sendMessage`;

        phones = phoneRef.current.value.split(",");

        setTotalMessage(Number(phones.length));

        let invalidNumbers = checkInvalidNumbers(phones);

        if (invalidNumbers.length === 0) {

            if (phoneRef.current.value) {

                if (phones.length > 0) {

                    if (isSelected) {

                        apiURL = `https://messagesapi.co.in/chat/sendMessageFile/${userInfo.uid}/${selectedDeviceRef.current[0]}`;


                        if (fileImportData.current.length > 0 && isFileImportSelected) {


                            if (message.messageType === "defaultMessage") {

                                sendFormatedFileMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);

                            } else {

                                sendFormatedFileMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);
                            }
                        } else {
                            sendFileMessage(selectedDeviceRef.current[0]);
                        }


                    } else {

                        if (fileImportData.current.length > 0 && isFileImportSelected) {

                            if (message.messageType === "defaultMessage") {

                                sendFormatedMessage(fileImportData.current[i].phone, message.message, fileImportData.current[i]);

                            } else {

                                sendFormatedMessage(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                            }

                        } else {

                            sendMessageMulti(fileImportData.current[i].phone, fileImportData.current[i].message, fileImportData.current[i]);

                        }
                    }

                } else {

                    setError("Phone number is missing");

                }
            } else {

                setError("Phone is not valid");

            }

        } else {

            setError(`Check this number ${invalidNumbers.join(",")}`);
            invalidNumbers = [];

        }
    };

    const handleSelectedDevice = (e) => {

        if (e.target.value) {
            selectedDeviceRef.current.push(e.target.value)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)
        } else {
            setIsDeviceSelected(false)
        }
    }

    const handleSelectTemplate = (e) => {
        if (e.target.value) {
            setMessage((prevData) => {
                return { ...prevData, "message": e.target.value, "messageType": "defaultMessage" };
            });

            setTemplateSelected(!templateSelected)

        } else {
            setMessage((prevData) => {
                return { ...prevData, "message": "", "messageType": "" };
            });

            setTemplateSelected(!templateSelected)
        }
    }

    const handleQuillChange = (value) => {

        setMessage((prevData) => {
            return { ...prevData, "message": value };
        });
    };

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    let jsonData = []; // Collection for all rows with column mapping

    const handleExcelFile = async (e) => {
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        }

        let file = phonesFile.current.files[0];

        await readXlsxFile(file).then((rows) => {
            // Extract headers from the first row
            const headers = rows[0].map((header) => header.toLowerCase()); // Convert headers to lowercase

            // Process subsequent rows and map to JSON objects
            rows.slice(1).forEach((row) => {
                let rowObject = {};
                row.forEach((cell, index) => {
                    rowObject[headers[index]] = cell !== null ? cell.toString().trim() : null;
                });
                jsonData.push(rowObject);
            });

            // Update your required states or references
            if (jsonData.length > 0) {
                setIsFileImportSelected(true);
            }

            fileImportData.current = jsonData;

            // If 'phone' is one of the columns, update related references
            const phones = jsonData.map((row) => row.phone).filter((phone) => phone); // Assuming 'phone' is one of the headers
            phoneRef.current.value = phones.join(",");
            setTotalMessage(Number(phones.length));
        });
    };

    const checkInvalidNumbers = (numbers) => {
        let invNumber = numbers.filter((number) => number.length !== 12);
        return invNumber;
    };

    const resetAll = () => {
        setStatus("");
        setError("");
        setIsClicked(false);
        setSelectedFile(null);
        setIsSelected(false);
        setTotalMessage(0);
        setSentMessage(0);
        setMessage({});
        setSuccess("");
        setIsScheduleProcess(false);
        setIsScheduleClicked(false);
        setTemplateSelected(false);
        setFileName('Choose Excel File');
        jsonData = [];
        fileRef.current.value ? fileRef.current.value = "" : fileRef.current = "";
        phonesFile.current.value ? phonesFile.current.value = "" : phonesFile.current = "";
        phoneRef.current.value ? phoneRef.current.value = "" : phoneRef.current = "";
    };

    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {

        if (templateData) {
            let data = templateData.templateByUID;

            setTemplates(data);
        }
    }, [templateData, templateLoading]);

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if ((error)) {
            setTimeout(() => {
                document.getElementById("multi").reset();
                resetAll();
                window.location.reload(false);
            }, 2500);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {

        if ((sentMessage === totalMessage)) {
            setTimeout(() => {
                document.getElementById("multi").reset();
                resetAll();
                window.location.reload(false);
            }, 2500);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentMessage, totalMessage]);

    const handleSelectionChange = useCallback(() => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        const selection = editor.getSelection();
        if (selection) {
            cursorRef.current = selection.index; // Update cursor position
        }
    }, []);

    useEffect(() => {
        const editor = quillRef.current.getEditor();
        editor.on('selection-change', handleSelectionChange);

        return () => {
            editor.off('selection-change', handleSelectionChange);
        };

    }, [handleSelectionChange]);

    const insertEmoji = (emoji) => {
        const editor = quillRef.current.getEditor();
        const cursorPosition = cursorRef.current;

        if (cursorPosition !== undefined) {
            editor.insertText(cursorPosition, emoji);
        }
    };

    return (
        <Container>
            <form id="multi" className="bg-gray-100">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-4 pt-6">
                    {/* Left Column */}
                    <div className="md:col-span-9 h-[80vh] md:h-screen overflow-y-scroll">
                        <div className="flex flex-col space-y-6">
                            {/* Connected Device Selection */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                                <p className="text-sm font-semibold text-gray-600">CONNECTED DEVICE:</p>
                                <select
                                    className="w-full p-2 rounded-md border border-gray-300"
                                    onChange={handleSelectedDevice}
                                >
                                    <option name="" value="" key="select device">Select device</option>
                                    {devices &&
                                        devices.map((device) => (
                                            <option
                                                key={device.deviceName}
                                                name={device.deviceName}
                                                value={device.deviceName}
                                            >
                                                {device.deviceName}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            {/* Template Selection */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                                <p className="text-sm font-semibold text-gray-600">SELECT TEMPLATE:</p>
                                <select
                                    className="w-full p-2 rounded-md border border-gray-300"
                                    onChange={handleSelectTemplate}
                                >
                                    <option name="" value="" key="Select Template">Select Template</option>
                                    {templates &&
                                        templates.map((template) => (
                                            <option
                                                key={template.templateName}
                                                name={template.templateName}
                                                value={template.body}
                                            >
                                                {template.templateName}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            {/* Sample File & Excel Upload */}
                            <div className="flex items-center justify-between">
                                <p className="text-xs font-bold uppercase text-gray-600">
                                    Download Sample Excel File:
                                </p>
                                <a
                                    href="./assets/samples.xlsx"
                                    className="text-sm font-semibold text-blue-600"
                                >
                                    <DownloadCloud className="inline-block" />
                                </a>
                            </div>
                            <div className="flex items-center justify-between">
                                <label
                                    htmlFor="input"
                                    className="text-sm font-semibold text-gray-600"
                                >
                                    Phone No:
                                </label>
                                <input
                                    type="file"
                                    id="input"
                                    ref={phonesFile}
                                    onChange={handleExcelFile}
                                    accept=".xlsx"
                                    disabled={isClicked}
                                    style={{ display: "none" }}
                                />
                                <label
                                    htmlFor="input"
                                    className="cursor-pointer bg-blue-500 text-white px-4 py-1 rounded-md"
                                >
                                    {fileName || "Upload File"}
                                </label>
                            </div>

                            {/* Phone Input */}
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="w-full rounded-md p-2 border border-gray-300"
                                onChange={handleMessage}
                                ref={phoneRef}
                                disabled={isClicked}
                                placeholder="Enter phone numbers, separated by commas"
                            />

                            {/* Responsive Radio Buttons */}
                            <div className="flex flex-wrap justify-between items-center gap-2">
                                <div>
                                    <label htmlFor="message" className="text-sm font-serif font-semibold text-gray-600">
                                        <span className="text-sm text-blue-500 font-semibold">Que: {totalMessage}</span>{" "}
                                        |{" "}
                                        <span className="text-sm text-green-500 font-semibold">Sent: {sentMessage}</span>
                                    </label>
                                </div>
                                <div className="flex gap-2">
                                    <label className="flex items-center text-gray-600 gap-2">
                                        <input
                                            type="radio"
                                            name="messageType"
                                            value="defaultMessage"
                                            onChange={handleMessage}
                                            checked={message.messageType === "defaultMessage" || templateSelected}
                                        />
                                        <span>Template Message</span>
                                    </label>
                                    <label className="flex items-center text-gray-600 gap-2">
                                        <input
                                            type="radio"
                                            name="messageType"
                                            value="sheetMessage"
                                            onChange={handleMessage}
                                            checked={message.messageType === "sheetMessage"}
                                        />
                                        <span>Sheet Message</span>
                                    </label>
                                </div>
                            </div>

                            {/* Message Input */}
                            <div>

                                <div className="w-full bg-white rounded-md p-2 h-[200px]">
                                    <ReactQuill
                                        style={{ height: "150px" }}
                                        ref={quillRef}
                                        name="message"
                                        id="message"
                                        onChange={handleQuillChange}
                                        value={message?.message}
                                        disabled={isClicked}
                                        placeholder="Message"
                                        theme="snow"
                                        readOnly={templateSelected}
                                    />
                                </div>
                            </div>


                            {/* Attach File */}
                            <div>
                                <label htmlFor="file" className="text-sm font-semibold text-gray-600">
                                    Attach File:
                                </label>
                                <input
                                    type="file"
                                    id="file"
                                    name="file"
                                    ref={fileRef}
                                    disabled={isClicked}
                                    onChange={changeHandler}
                                    accept=".txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                            </div>
                            {selectedFile && (
                                <div className="text-sm text-gray-600">
                                    <p>Filename: {selectedFile.name}</p>
                                    <p>Filetype: {selectedFile.type}</p>
                                    <p>Size: {selectedFile.size} bytes</p>
                                    <p>
                                        Last Modified:{" "}
                                        {selectedFile.lastModifiedDate.toLocaleDateString()}
                                    </p>
                                </div>
                            )}

                            {/* Scheduled DateTime */}
                            {isScheduleClicked && (
                                <div>
                                    <label
                                        htmlFor="dateTime"
                                        className="block text-sm font-semibold text-gray-600 mb-2"
                                    >
                                        Schedule DateTime:
                                    </label>
                                    <input
                                        type="datetime-local"
                                        id="dateTime"
                                        name="dateTime"
                                        className="w-full rounded-md border p-2"
                                        ref={dateTimeRef}
                                        onChange={handleMessage}
                                    />
                                </div>
                            )}

                            {/* Status Messages */}
                            <div className="text-center">
                                {success && (
                                    <Alerts alertType={"Success"}>{success}</Alerts>
                                )}
                                {error && (
                                    <Alerts alertType={"Error"} className="text-red-500 text-lg font-semibold">{error}</Alerts>
                                )}
                            </div>

                            {/* Buttons */}
                            <div className="flex justify-end space-x-4">
                                <button
                                    className="rounded-md bg-orange-400 px-4 py-2 text-white"
                                    onClick={sendMessage}
                                    disabled={isClicked || isScheduleClicked || !isDeviceSelected}
                                >
                                    {!isDeviceSelected
                                        ? "Select Device"
                                        : isClicked
                                            ? "Sending..."
                                            : "Send Now"}
                                </button>
                                {!isScheduleClicked && (
                                    <button
                                        className="rounded-md bg-blue-400 px-4 py-2 text-white"
                                        onClick={() => setIsScheduleClicked(true)}
                                    >
                                        Schedule
                                    </button>
                                )}
                                {isScheduleClicked && !isScheduleProcess && (
                                    <button
                                        className="rounded-md bg-blue-400 px-4 py-2 text-white"
                                        onClick={sendScheduleMessage}
                                        disabled={
                                            isClicked || !isDeviceSelected || !dateTimeRef.current.value
                                        }
                                    >
                                        Set Schedule
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Emoji Column */}
                    <div className="md:col-span-3 hidden md:block">
                        <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
                    </div>
                </div>
            </form>

        </Container>
    );
};

export default MultiMessage;
